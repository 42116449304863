import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getProtocolRelativePathUrl } from '#/utils/url';
import style from './AssetBackground.scss';

/**
 * AssetBackground Component
 * @param {Object} props Component Props
 * @param {String} props.backgroundUrl Background Image
 * @returns {React.ReactComponentElement} AssetBackground Component
 */
const AssetBackground = ({ backgroundUrl, playerShown }) => {
  return (
    <div
      className={classNames(style.container, {
        [style.hide]: playerShown
      })}
    >
      <img
        src={getProtocolRelativePathUrl(backgroundUrl)}
        alt=""
        id="asset-background-img"
      />
    </div>
  );
};

AssetBackground.propTypes = {
  backgroundUrl: PropTypes.string,
  playerShown: PropTypes.bool
};

export default AssetBackground;
