import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { withFocus } from '@accedo/vdkweb-navigation';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';

import { AuthContext } from '#/context/AuthContext';
import { actionClickMenu } from '#/services/analytics';
import { getMenu } from '#/services/cms';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import useAppConfig from '#/hooks/useAppConfig';
import useI18n from '#/hooks/useI18n';
import { navIdMap } from '#/utils/navigationHelper';
import toKebabCase from '#/utils/toKebabCase';
import Logo from '#/components/Logo/Logo';
import isRTL from '#/utils/isRTL';
import dominicImage from '#/static/images/dominic-small.svg';
import adiaImage from '#/static/images/adia-small.svg';
import kidImage from '#/static/images/kiddo-small.svg';

import styles from './menu.scss';
import MenuItem from './MenuItem';

const NAV_IDS = navIdMap.MENU.HEADER;

const mockProfiles = [
  { name: 'DOMINIC', displayName: 'DOMINIC', image: dominicImage },
  { name: 'ADIA', displayName: 'ADIA', image: adiaImage },
  { name: 'KID', displayName: 'KIDDO', image: kidImage }
];

/**
 * Nav Component
 * @param {Object} props Component props
 * @param {React.ReactNode} props.children Component children
 * @param {String} props.className Component css class
 * @returns {React.ReactElement} Nav React Component
 */
const Nav = ({ children, className, dir = 'ltr' }) => (
  <nav className={className} dir={dir}>
    {children}
  </nav>
);

Nav.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dir: PropTypes.string
};

const FocusNav = withFocus(Nav);

/**
 * Menu Component
 * @param {Object} props Component Props
 * @param {Object} props.nav Navigation object used for focus
 * @param {string} props.nav.id Top Menu Id
 * @param {string} props.nav.itemId Menu item Id
 * @param {string} entryId The Accedo One entry Id
 * @param {Array} items Array with static items for the meny instead using A1
 * @returns {React.ReactComponentElement} Menu Component
 */
const Menu = ({ entryId, nav, items, imgLogo, logoClassName }) => {
  const authContext = useContext(AuthContext);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [user, setUser] = useState(authContext.getUser());
  const [menu, setMenu] = useState({});

  const { config = {} } = useAppConfig();
  const routesMapping = config.routesMapping || [];
  const historyPush = useHistoryPush();
  const { currentLocale = {} } = useI18n();
  const { dir, code: localeCode } = currentLocale;
  const rtl = isRTL(dir);

  const getFirstName = fullName => {
    if (!fullName) {
      return null;
    }

    return fullName.split(' ')[0];
  };

  useEffect(() => {
    if (!user) {
      setSelectedProfile(mockProfiles[0]);
      return;
    }
    const authData = authContext.getUser();
    if (user?.displayName !== authData?.displayName) {
      setUser(authData);
    }
    const profile = mockProfiles.find(p => {
      return (
        user?.displayName?.toUpperCase() ===
        getFirstName(p.displayName).toUpperCase()
      );
    });
    setSelectedProfile(profile);
  }, [user, authContext]);

  /**
   * @typedef MenuItem
   * @type {Object}
   * @property {String} label The menu item name that will show up in the application
   * @property {String} route The route / path to the page you want to navigate to
   * @property {String} iconUrl The icon url to be displayed (takes precedence over label)
   * @property {Boolean} hiddenWhenAuth Indicate if the  menu item is hidden when the user is
   * authenticated / logged in
   * @property {'primary'|'secondary'} type The type of menu item. If primary will be display at the
   * left side, secondary will be display at the right side.
   * @property {React.ReactComponentElement} content The component to be displayed (takes precedence over
   * iconUrl)
   */

  /**
   * Helper function to push to a new route when it is pressed/clicked
   * @param {String} route Route destination
   * @returns {void}
   */
  const onMenuClick = ({ page, displayText }) => {
    actionClickMenu({ menuTitle: displayText });
    const cmsMenuItemForPage = routesMapping.find(
      menuItem => menuItem.id === page?.id
    );
    if (cmsMenuItemForPage) {
      historyPush({ path: cmsMenuItemForPage.route });
    } else {
      console.error(
        'Current Menu Items doesnt have an associated route, please review'
      );
      historyPush({ path: '/' });
    }
  };

  /**
   * Creates a menu with focus management
   * @returns {void}
   */
  const createMenu = useCallback(async () => {
    const { primaryItems, secondaryItems, title } = await getMenu({
      items,
      entryId,
      isAuthenticated: authContext.isAuthenticated,
      locale: localeCode
    });

    setMenu({
      title,
      primaryItems,
      secondaryItems
    });
  }, [authContext.isAuthenticated, entryId, items, localeCode]);

  useEffect(() => {
    if (localeCode) {
      createMenu();
    }
  }, [createMenu, user, localeCode]);

  if (!menu.primaryItems) {
    return <div />;
  }

  const primaryItems = [...menu.primaryItems];
  const secondaryItems = [...menu.secondaryItems];

  const primaryItemNavPrefix = `${NAV_IDS.MENU_ITEM}primary-item-`;
  const secondaryItemNavPrefix = `${NAV_IDS.MENU_ITEM}secondary-item-`;

  return (
    <FocusNav className={styles.menu} nav={nav} dir={dir}>
      <div className={styles.menuItemsContainer}>
        <div className={styles.logoContainer}>
          <Logo src={imgLogo} className={logoClassName} />
        </div>
        <FocusDiv
          className={`${styles.menu} ${styles.mainMenu}`}
          dir={dir}
          nav={{
            id: `${NAV_IDS.MENU_ITEM}0`,
            parent: NAV_IDS.MENU,
            forwardFocus: `${primaryItemNavPrefix}${
              rtl
                ? toKebabCase(primaryItems[primaryItems.length - 1].displayText)
                : toKebabCase(primaryItems[0].displayText)
            }`,
            useLastFocus: true,
            nextleft:
              !rtl || secondaryItems.length === 0
                ? ''
                : `${NAV_IDS.MENU_ITEM}1`,
            nextright:
              rtl || secondaryItems.length === 0 ? '' : `${NAV_IDS.MENU_ITEM}1`
          }}
        >
          {primaryItems.map((primaryItem, index) => {
            const primaryItemId = `${primaryItemNavPrefix}${toKebabCase(
              primaryItem.displayText
            )}`;
            const secondayNextItem = rtl ? `${secondaryItemNavPrefix}0` : ``;

            const primaryItemNextleft =
              index === 0
                ? secondayNextItem
                : `${primaryItemNavPrefix}${toKebabCase(
                    primaryItems[index - 1].displayText
                  )}`;
            const primaryItemNextRight =
              index >= primaryItems.length - 1
                ? `${secondaryItemNavPrefix}0`
                : `${primaryItemNavPrefix}${toKebabCase(
                    primaryItems[index + 1].displayText
                  )}`;
            return (
              <MenuItem
                style={styles}
                colorIndex={index + 1}
                data={primaryItem}
                key={primaryItemId}
                nav={{
                  id: primaryItemId,
                  nextleft: primaryItemNextleft,
                  nextright: primaryItemNextRight,
                  parent: NAV_IDS.MENU,
                  useLastFocus: true
                }}
                onClick={() => onMenuClick(primaryItem)}
              />
            );
          })}
          {secondaryItems.map((secondaryItem, index) => {
            const colorIndex = index + primaryItems.length + 1;
            const secondaryItemId = `${secondaryItemNavPrefix}${index}`;
            const nextPrimaryItemIndex = rtl ? 0 : primaryItems.length - 1;
            const secondaryItemNextLeft =
              index === 0
                ? `${primaryItemNavPrefix}${toKebabCase(
                    primaryItems?.[nextPrimaryItemIndex]?.displayText
                  )}`
                : `${secondaryItemNavPrefix}${index - 1}`;
            const secondaryItemNextRight =
              index >= secondaryItems.length - 1
                ? ''
                : `${secondaryItemNavPrefix}${index + 1}`;

            if (selectedProfile && selectedProfile.image) {
              secondaryItem.image = selectedProfile.image;
            }
            return (
              <MenuItem
                style={styles}
                colorIndex={colorIndex}
                data={secondaryItem}
                key={secondaryItemId}
                nav={{
                  id: secondaryItemId,
                  nextleft: rtl
                    ? secondaryItemNextRight
                    : secondaryItemNextLeft,
                  nextright: rtl
                    ? secondaryItemNextLeft
                    : secondaryItemNextRight,
                  parent: NAV_IDS.MENU,
                  useLastFocus: true
                }}
                onClick={() => onMenuClick(secondaryItem)}
              />
            );
          })}
        </FocusDiv>
      </div>
    </FocusNav>
  );
};

Menu.propTypes = {
  nav: PropTypes.object.isRequired,
  entryId: PropTypes.string,
  imgLogo: PropTypes.string,
  logoClassName: PropTypes.any,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default React.memo(Menu);
