/**
 * @module models/CMS
 */
/**
 * @typedef {Object} VikiElevateMenuItem
 * @property {String} title title (on CMS)
 * @property {String} displayText menu text
 * @property {Page} page page where to navigate
 * @property {String} itemGroup tag to group the icons on
 * @property {String} viaIconLabel svg via 4 icon
 * @property {Boolean} requireauthentication Only shown when auth
 * @property {Boolean} hiddenWhenAuth Hidden when auth
 */

/**
 * Menu items model
 * @param {Object} rawItem Item
 * @returns {VikiElevateMenuItem} Item - The {@link VikiElevateMenuItem} representation
 */
const vikiElevateMenuItem = rawItem => {
  /**
   * Get the item's displayText
   * @returns {String} displaytext
   */
  const getDisplayText = () => {
    const { displayText } = rawItem;
    return displayText;
  };

  /**
   * Get the item's page
   * @returns {Page} page item
   */
  const getPage = () => {
    const { page } = rawItem;
    return page;
  };

  /**
   * Get the item's item tag to group the icons on
   * @returns {Page} item group
   */
  const getItemGroup = () => {
    const { itemGroup } = rawItem;
    return itemGroup;
  };

  /**
   * Get the item's svg via 4 icon
   * @returns {Page} svg via 4 icon item
   */
  const getViaIconLabel = () => {
    const { viaIconLabel } = rawItem;
    return viaIconLabel;
  };

  /**
   * Get the item's route
   * @returns {String} Route path
   */
  const getRoute = () => {
    const { route } = rawItem;
    return route;
  };

  /**
   * Get the items's type
   * @returns {String} Type
   */
  const getType = () => {
    const { type } = rawItem;
    return type;
  };

  /**
   * Checks if the item must be hidden when there is user logged in
   * @returns {String} hiddenWhenAuth
   */
  const isHiddenWhenAuth = () => {
    const { hiddenWhenAuth } = rawItem;
    return hiddenWhenAuth;
  };

  /**
   * Checks if the item must be hidden when there is user logged in
   * @returns {String} requiresAuthentication
   */
  const requireAuthentication = () => {
    const {
      requiresAuthentication,
      requireAuthentication: requireAuthenticationField
    } = rawItem;
    return requireAuthenticationField !== undefined
      ? requireAuthenticationField
      : requiresAuthentication;
  };

  /**
   * Get the items's font awesome icon
   * @returns {String} faIcon
   */
  const getFAIcon = () => {
    const { fontAwesomeIcon } = rawItem;
    return fontAwesomeIcon;
  };

  /**
   * Get the items's image
   * @returns {String} Image url
   */
  const getImage = () => {
    const { image } = rawItem;
    return image && image.fileUrl;
  };

  /**
   * Get the items's icon
   * @returns {String} Icon
   */
  const getIcon = () => {
    const { icon } = rawItem;
    return icon?.length ? icon : null;
  };

  return {
    displayText: getDisplayText(),
    page: getPage(),
    icon: getIcon(),
    image: getImage(),
    itemGroup: getItemGroup(),
    viaIconLabel: getViaIconLabel(),
    requireAuthentication: requireAuthentication(),
    hiddenWhenAuth: isHiddenWhenAuth(),
    route: getRoute(),
    faIcon: getFAIcon(), // TODO: remove, deprecated
    type: getType() // TODO: remove, deprecated
  };
};

export default vikiElevateMenuItem;
