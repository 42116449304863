import React from 'react';
import PropTypes from 'prop-types';

import AssetItem from '../AssetItem/AssetItem';
import theme from './continueWatchingItem.scss';

const ContinueWatchingItem = props => {
  const { data, width, height } = props;

  // data.images = [{ url: props.data.coverUrl }];

  if (data.assetType === 'episode') {
    data.title = data.showTitle;
  }

  const itemStyle = {
    ...(width ? { width } : {}),
    ...(height ? { height } : {})
  };

  return (
    <div className={theme.continueWatchingItem} style={itemStyle}>
      <AssetItem {...props} isContinueWatching theme={theme} />
    </div>
  );
};

ContinueWatchingItem.propTypes = {
  data: PropTypes.object,
  height: PropTypes.any,
  width: PropTypes.any
};

export default ContinueWatchingItem;
