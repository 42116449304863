import { ITEM_TYPES, IMAGE_TYPE } from '#/config/constants';

/** Dimensions in px */
export const ITEM_SIZE = {
  [ITEM_TYPES.Wide]: { width: 573, height: 380, itemSpacing: 20 },
  [ITEM_TYPES.Portrait]: { width: 336, height: 504, itemSpacing: 20 },
  [ITEM_TYPES.GridPortrait]: { width: 330, height: 496 },
  [ITEM_TYPES.CategoriesLandscape]: {
    width: 573,
    height: 380,
    itemSpacing: 20
  },
  [ITEM_TYPES.GridLandscape]: {
    width: 590,
    height: 404,
    itemSpacing: 25
  },
  [ITEM_TYPES.FeaturedCarouselPortrait]: { width: 330, height: 496 },
  [ITEM_TYPES.Landscape]: { width: 450, height: 260 },
  [ITEM_TYPES.Square]: { width: 200, height: 200 },
  [ITEM_TYPES.Bookmark]: {
    width: 570,
    height: 320.6,
    containerHeight: 380
  },
  [ITEM_TYPES.Categories]: { width: 336, height: 120, itemSpacing: 20 },
  [ITEM_TYPES.CategoriesKids]: { width: 336, height: 438, itemSpacing: 20 },
  [ITEM_TYPES.SeasonEpisode]: {
    width: 573,
    height: 450,
    containerHeight: 470
  },
  [ITEM_TYPES.SearchMovie]: { width: 250, height: 375 },
  [ITEM_TYPES.SearchEpisode]: { width: 444, height: 250 },
  [ITEM_TYPES.SearchResult]: {
    width: 253,
    height: 378,
    containerHeight: 378
  },
  [ITEM_TYPES.HeroBanner]: { width: 1920, height: 864 }
};

export const CUSTOM_IMAGE_FORMAT = {
  [ITEM_TYPES.Wide]: ITEM_TYPES.Wide,
  [ITEM_TYPES.GridLandscape]: ITEM_TYPES.GridLandscape,
  [ITEM_TYPES.CategoriesLandscape]: IMAGE_TYPE.BACKDROP_CLEAN,
  [ITEM_TYPES.SearchMovie]: IMAGE_TYPE.POSTER,
  [ITEM_TYPES.SearchEpisode]: IMAGE_TYPE.BACKDROP,
  [ITEM_TYPES.SearchResult]: IMAGE_TYPE.POSTER,
  [ITEM_TYPES.Square]: ITEM_TYPES.Square,
  [ITEM_TYPES.Bookmark]: IMAGE_TYPE.BACKDROP_CLEAN
};
