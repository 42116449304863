/*
 * Managing the routes for the application views.
 * Register new routes if introducing new views.
 */

import React from 'react';

import ModularPage from '#/views/ModularPage/ModularPage';

import { PAGE_TEMPLATES } from '#/config/constants';

import ViewDoesNotExistInTheApp from '#/views/ViewDoesNotExistInTheApp/ViewDoesNotExistInTheApp';

const About = React.lazy(() => import('../views/About/About'));
const CategoryListing = React.lazy(() =>
  import('../views/Listing/CategoryListing')
);

const Detail = React.lazy(() => import('../views/Detail/Detail'));
const Search = React.lazy(() => import('../views/Search/Search'));
const ViewAll = React.lazy(() => import('../views/ViewAll/ViewAll'));
const Login = React.lazy(() => import('../views/Login/Login'));
const EPGPage = React.lazy(() => import('../views/EPGPage/EPGPage'));
const MyContent = React.lazy(() => import('../views/MyContent/MyContent'));
const Info = React.lazy(() => import('../views/Info/Info'));
const PairingCode = React.lazy(() =>
  import('../views/PairingCode/PairingCode')
);
const Player = React.lazy(() => import('../views/Player/Player'));
const Profile = React.lazy(() => import('../views/Profile/Profile'));
const ResetPassword = React.lazy(() =>
  import('../views/ResetPassword/ResetPassword')
);
const ParentalControl = React.lazy(() =>
  import('../views/ParentalControl/ParentalControl')
);
const ProgramDetailPage = React.lazy(() =>
  /* webpackChunkName: "ProgramDetailPage" */
  import('../views/ProgramDetail/ProgramDetailPage')
);

const viewMap = [
  {
    template: PAGE_TEMPLATES.modular,
    viewFn: ({ id }) => <ModularPage pageId={id} />
  },
  {
    template: PAGE_TEMPLATES.myContent,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <MyContent pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.epg,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <EPGPage pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.profile,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <Profile pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.search,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <Search pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.signin,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <Login pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.info,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <Info pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.player,
    viewFn: ({ id, xdkPlatform }) => (
      <React.Suspense fallback={<>...</>}>
        <Player pageId={id} xdkPlatform={xdkPlatform} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.category,
    hasSubroute: true,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <CategoryListing pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.pairing,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <PairingCode pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.resetPassword,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <ResetPassword pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.viewAll,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <ViewAll pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.showDetail,
    hasSubroute: true,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <Detail pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.movieDetail,
    hasSubroute: true,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <Detail pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.programDetail,
    hasSubroute: true,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <ProgramDetailPage pageId={id} />
      </React.Suspense>
    )
  },
  {
    template: PAGE_TEMPLATES.parentalControl,
    viewFn: ({ id }) => (
      <React.Suspense fallback={<>...</>}>
        <ParentalControl pageId={id} />
      </React.Suspense>
    )
  }
];

const routes = (xdkPlatform, routesMapping) => {
  const baseRoutes = [
    {
      path: '/about',
      element: (
        <React.Suspense fallback={<>...</>}>
          <About />
        </React.Suspense>
      )
    }
  ];
  const dynamicRoutes = routesMapping.map(menuItem => {
    const itemView = viewMap.find(
      viewMapItem => viewMapItem.template === menuItem.template
    );

    if (!itemView) {
      console.error(
        `It's not possible to map the route "${menuItem.route}" from Accedo Control, because this view still doesn't exist in Elevate.`
      );

      return {
        path: menuItem.route,
        element: <ViewDoesNotExistInTheApp />
      };
    }

    const itemViewFn = itemView?.viewFn;
    if (itemView?.hasSubroute) {
      return {
        path: menuItem.route,
        children: [
          {
            path: ':subroute',
            element: itemViewFn({ id: menuItem.id, xdkPlatform })
          }
        ]
      };
    }
    return {
      path: menuItem.route,
      element: itemViewFn({ id: menuItem.id, xdkPlatform })
    };
  });
  const allRoutes = [...baseRoutes, ...dynamicRoutes];
  return allRoutes;
};

export default routes;
