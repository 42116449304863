import React, { useState, memo } from 'react';
import Ellipsis from 'react-ellipsis-pjs';
import { withFocus } from '@accedo/vdkweb-navigation';
import Nav from '#/interfaces/Nav';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import style from './seasonEpisode.scss';

interface SeasonEpisodeItem {
  data: any; // "any" until we review the data structure.
  nav: Nav;
  onClick: () => any | void;
  width: number | string;
  height: number | string;
}

const SeasonEpisode = ({
  onClick,
  data,
  nav,
  width: widthProp,
  height: heightProp
}: SeasonEpisodeItem) => {
  const [width] = useState(widthProp);
  const [height] = useState(heightProp);

  const { progress, title, coverUrl, tvShowDescription } = data;

  const className = nav.isFocused
    ? `${style.episode} ${style.focused}`
    : style.episode;

  const handleOnMouseOver = () => {
    const element = document.getElementById('asset-background-img');
    if (element) {
      element.style.filter = 'blur(10px)';
    }
  };

  const handleonMouseOut = () => {
    const element = document.getElementById('asset-background-img');
    if (element) {
      element.style.filter = 'blur(0px)';
    }
  };

  const getDuration = () => {
    return `${parseInt(data.duration.match(/\d+/)[0], 10)}m`;
  };

  return (
    <div style={{ width, height }} className={style.seasonEpisodeContainer}>
      <div
        className={className}
        onClick={onClick}
        onMouseEnter={nav.onMouseOver}
        onMouseLeave={nav.onMouseOut}
        role="button"
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleonMouseOut}
      >
        <div className={style.containerImage}>
          <ImageWithPreload
            className={style.coverImage}
            src={coverUrl}
            alt={title}
          />
          {progress > 0 && (
            <div className={style.progress}>
              <span style={{ width: `${progress}%` }} />
            </div>
          )}
          <div className={style.containerMetadata}>
            <div className={style.titleContainer}>
              <div className={style.title}>{title}</div>
              <div className={style.duration}>{getDuration()}</div>
            </div>
            <div className={style.description}>{tvShowDescription}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const propsAreEqual = (
  prevProps: SeasonEpisodeItem,
  nextProps: SeasonEpisodeItem
) => {
  const { data: prevData, nav: prevNav } = prevProps || {};
  const { data: nextData, nav: nextNav } = nextProps || {};

  return (
    prevData.displayText === nextData.displayText &&
    prevData.viewAll === nextData.viewAll &&
    prevNav.isFocused === nextNav.isFocused &&
    prevNav.nextleft === nextNav.nextleft &&
    prevNav.nextright === nextNav.nextright
  );
};

export default memo(withFocus(SeasonEpisode), propsAreEqual);
