import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import defaultSrc from '#/static/images/elevate_color_negative.svg';
import kidsSrc from '#/static/images/elevate_kids_color_negative.svg';
import { AuthContext } from '#/context/AuthContext';

import styles from './logo.scss';

const Logo = ({ src = defaultSrc, className: propClassName }) => {
  const authContext = useContext(AuthContext);
  const isKidProfile = authContext.getUser()?.isKidProfile;
  const className = `${styles.logo} ${propClassName} ${isKidProfile &&
    styles.kidsLogo}`;

  return (
    <img src={isKidProfile ? kidsSrc : src} className={className} alt="Logo" />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string
};

export default React.memo(Logo);
