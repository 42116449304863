import { ITEM_SIZE } from '#/components/Item/itemHelper';
import { DETAIL_ROUTES } from '#/config/constants';
import getResolution from '#/utils/getResolution';
import generateRandomRating from '#/utils/generateRating';

import { getCloudinaryImage } from '../utils';

/**
 * @module models/OVP
 */

/**
 * Amount of stars to display as credits
 * @type {Number}
 */
const CREDITS_LENGTH = 5;

/**
 * Item's size.
 * It's used to get the image's template Url with the correct size
 * @type {String}
 */
const ITEM_TYPE_PORTRAIT_SIZE = ITEM_SIZE.portrait;
const ITEM_TYPE_LANDSCAPE_SIZE = ITEM_SIZE.landscape;
const ITEM_TYPE_SQUEARE_SIZE = ITEM_SIZE.square;

/**
 * @typedef {Object} Movie
 * @property {String} id Movie ID
 * @property {String} categoryId Movie Category ID
 * @property {String} coverUrl Movie Cover Image URL
 * @property {String} metadata Movie Metadata
 * @property {String} title Movie Tile
 * @property {String} description Movie Description
 * @property {Array<Credit>} credits Movie Credits
 * @property {String} duration Movie Duration
 * @property {String} videoUrl Movie Video URL
 * @property {String} link Movie route to use in the application to navigate to its detail
 * @property {String} category Movie Category Title
 * @property {String} type type of the content, in case is needed for a global check. Value hardcoded to 'movie'
 * @property {String} categories Categories array
 * @property {String} publishedDate Publication date
 * @property {Array<Image>} images Array of the images of the movie
 */

/**
 * @description
 * Movie Model
 * @param {Object} rawMovie Movie
 * @returns {Movie} Movie
 */
const Movie = rawMovie => {
  const {
    id,
    categories,
    description,
    images,
    publishedDate,
    contents,
    credits: rawCredits,
    title
  } = rawMovie;

  /**
   * Full image size
   */
  const { width, height } = getResolution();

  /**
   * Get the Movie's id
   * @returns {String} ID
   */
  const getId = () => {
    return id;
  };

  /**
   * Get the first Movie's category id
   * @returns {String} Category ID
   */
  const getPrimaryCategoryId = () => {
    if (categories.length === 0) {
      return null;
    }
    const [primaryCategory] = categories;
    return primaryCategory.id;
  };

  /**
   * Get the Movie's categories
   * @returns {String} Categories
   */
  const getCategories = () => {
    return categories.map(category => category.title).join(', ');
  };

  /**
   * Get the Movie's primary category
   * @returns {String} Category
   */
  const getPrimaryCategory = () => {
    if (categories.length === 0) {
      return null;
    }
    const [category] = categories;
    return category.title;
  };

  /**
   * Get the first Movie's cover url
   * @returns {String} Cover URL
   */
  const getCoverUrl = ({ type = 'poster' } = {}) => {
    if (images.length === 0) {
      return null;
    }
    const filteredImages = images.filter(image => image.type === type);
    let coverImage = {};
    if (filteredImages.length) {
      [coverImage] = filteredImages;
    } else {
      [coverImage] = images;
    }
    return coverImage.url;
  };

  /**
   * Get the publication date
   * @returns {String} Publication date
   */
  const getPublishedDate = () => {
    return publishedDate;
  };

  /**
   * Get the Movie's year production
   * @returns {Number} Year Production
   */
  const getYear = () => {
    const date = new Date();
    date.setTime(publishedDate);
    return date.getFullYear();
  };

  /**
   * Get the Movie's duration
   * @returns {String} Duration
   */
  const getDuration = () => {
    const { duration } = contents[0];
    return `${Math.floor(duration / 1000 / 60)} mins`;
  };

  /**
   * Get the Movie's metadata
   * @returns {String} Metadata
   */
  const getMetadata = () => {
    const metadata = `${getYear()} \uff65 ${getDuration()} \uff65 ${getCategories()}`;
    return metadata;
  };

  /**
   * Get the Movie's title
   * @returns {String} Title
   */
  const getTitle = () => {
    return title;
  };

  /**
   * Get the Movie's link
   * @return {String} Link
   */
  const getLink = () => {
    const path = DETAIL_ROUTES.movie;
    const link = path.replace(':item-id:', id);
    return link;
  };

  /**
   * Get the Movie's description
   * @returns {String} Description
   */
  const getDescription = () => {
    return description;
  };

  /**
   * Get the Movie's credits
   * @returns {Array<Object>} Credits
   */
  const getCredits = () => {
    const [director, ...actors] = rawCredits.slice(0, CREDITS_LENGTH);
    const credits = [];
    credits.push({ role: 'Director', names: [director.name] });
    credits.push({ role: 'Cast', names: actors.map(actor => actor.name) });
    return credits;
  };

  /**
   * Get the Movie's video url. Hardcoded to our hosted video
   * @returns {String} Video URL
   */
  const getVideoUrl = () => {
    // return '//d1knl4odus8cue.cloudfront.net/bbb/bbb_sunflower_1080p_60fps_normal.mp4';
    return '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4'; // multiaudio
  };

  /**
   * Get the image resolution depending on the image type
   * @param {String} imageType getImages
   * @returns {Object} image size
   */
  const getImageTemplateUrlSize = imageType => {
    let imageSize = {};

    if (imageType === 'poster' || imageType === 'poster_clean') {
      imageSize = ITEM_TYPE_PORTRAIT_SIZE;
    } else if (imageType === 'backdrop_clean') {
      imageSize = {
        height,
        width
      };
    } else if (imageType === 'backdrop') {
      imageSize = ITEM_TYPE_LANDSCAPE_SIZE;
    } else if (imageType === 'logo') {
      imageSize = ITEM_TYPE_SQUEARE_SIZE;
    }

    return imageSize;
  };

  const getImages = () => {
    return images.map(img => {
      img.url = getCloudinaryImage({
        imgUrl: img.templateUrl,
        imgWidth:
          // Detail view background image size
          getImageTemplateUrlSize(img.type).width,
        width: img.width
      });
      return img;
    });
  };
  return {
    id: getId(),
    images: getImages(),
    title: getTitle(),
    link: getLink(),
    categoryId: getPrimaryCategoryId(),
    category: getPrimaryCategory(),
    coverUrl: getCoverUrl(),
    backgroundUrl: getCoverUrl({
      type: 'backdrop_clean'
    }),
    metadata: getMetadata(),
    description: getDescription(),
    credits: getCredits(),
    duration: getDuration(),
    videoUrl: getVideoUrl(),
    type: 'movie',
    publishedDate: getPublishedDate(),
    categories: getCategories(),
    rating: generateRandomRating()
  };
};

export default Movie;
