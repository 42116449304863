import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import { AuthContext } from '#/context/AuthContext';
import playful from '#/static/images/kids-categories/playful.png';
import brainPicking from '#/static/images/kids-categories/brain-picking.png';
import brave from '#/static/images/kids-categories/brave.png';
import musical from '#/static/images/kids-categories/musical.png';
import squidWard from '#/static/images/kids-categories/squidward.png';
import categoryItemTheme from './elevateCategoryItem.scss';

const categoriesMock = {
  family: {
    displayTitle: 'Playful',
    imageSrc: playful
  },
  sciencefiction: {
    displayTitle: 'Brain Picking',
    imageSrc: brainPicking
  },
  drama: {
    displayTitle: 'Brave',
    imageSrc: brave
  },
  talk: {
    displayTitle: 'Musical',
    imageSrc: musical
  },
  documentary: {
    displayTitle: 'Squidward',
    imageSrc: squidWard
  }
};

const ElevateCategoryItem = ({
  data,
  nav,
  width: widthProp,
  height: heightProp,
  onClick
}) => {
  const [width] = useState(widthProp);
  const [height] = useState(heightProp);
  const authContext = useContext(AuthContext);
  const isKidProfile = authContext.getUser()?.isKidProfile;

  const wrapperClassName = `${categoryItemTheme.wrapper} ${
    nav.isFocused ? categoryItemTheme.focused : ''
  }`;

  const kidsWrapperClassName = `${categoryItemTheme.kidsWrapper} ${
    nav.isFocused ? categoryItemTheme.kidsCategoryFocused : ''
  }`;

  if (isKidProfile) {
    const { displayTitle, imageSrc } =
      categoriesMock?.[data.title.toLowerCase().replace(' ', '')] || data.title;
    return (
      <div
        className={kidsWrapperClassName}
        style={{
          width,
          height,
          backgroundImage: `url(${imageSrc || brave})`,
          backgroundRepeat: 'no-repeat, no-repeat'
        }}
        onMouseOver={nav.onMouseOver}
        onMouseOut={nav.onMouseOut}
        onClick={onClick}
        role="button"
      >
        <div className={categoryItemTheme.glow} />
        <div className={categoryItemTheme.kidsTitle}>
          {displayTitle || data.title}
        </div>
      </div>
    );
  }

  return (
    <div
      className={wrapperClassName}
      style={{ width, height }}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
      onClick={onClick}
      role="button"
    >
      <div className={categoryItemTheme.title}>{data.title}</div>
    </div>
  );
};

ElevateCategoryItem.propTypes = {
  data: PropTypes.object,
  height: PropTypes.any,
  width: PropTypes.any,
  nav: PropTypes.object,
  onClick: PropTypes.func
};

export default withFocus(ElevateCategoryItem);
