/**
 * @module services/page
 * @description
 * Service to handle the fetch of menu items
 */
import cms from '#/providers/cms';
import pageModel from '#/models/page';
import { PROVIDER_TYPE } from '#/config/constants';

import fetcher from '../../helpers/fetcher';

const getPageLayout = async ({ id, locale }) => {
  const rawPage = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-${id}${locale ? `-${locale}` : ''}`,
    fetchFn: () => cms.getPageLayout({ id, options: { locale } })
  });

  rawPage.containers = rawPage.containers.map(c => {
    if (
      c.id === '6153028123eec60018226fe5' ||
      c.id === '61530331a0e8450017079ca8'
    ) {
      c.template = 'elevate-carousel-portrait';
    }
    return c;
  });

  const page = pageModel(rawPage);

  return page;
};

export { getPageLayout };
