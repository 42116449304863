import { ITEM_TYPES } from '#/config/constants';
import AssetItem from '#/components/Item/AssetItem/AssetItem';
import SeasonEpisode from '#/components/Item/SeasonEpisode/SeasonEpisode';
import CategoryItem from '#/components/Item/CategoryItem/CategoryItem';
import ContinueWatchingItem from '#/components/Item/ContinueWatchingItem/ContinueWatchingItem';
import ElevateCategoryItem from '#/components/Item/ElevateCategoryItem/ElevateCategoryItem';

export const ITEM_TYPE_TO_COMPONENT_MAP = {
  [ITEM_TYPES.Wide]: AssetItem,
  [ITEM_TYPES.Portrait]: AssetItem,
  [ITEM_TYPES.FeaturedCarouselPortrait]: AssetItem,
  [ITEM_TYPES.GridPortrait]: AssetItem,
  [ITEM_TYPES.Landscape]: AssetItem,
  [ITEM_TYPES.Square]: CategoryItem,
  [ITEM_TYPES.Bookmark]: ContinueWatchingItem,
  [ITEM_TYPES.Categories]: ElevateCategoryItem,
  [ITEM_TYPES.SeasonEpisode]: SeasonEpisode,
  [ITEM_TYPES.SearchMovie]: AssetItem,
  [ITEM_TYPES.SearchEpisode]: AssetItem,
  [ITEM_TYPES.SearchResult]: AssetItem
};

const getCardByType = (type = ITEM_TYPES.Portrait) => {
  const COMPONENT = ITEM_TYPE_TO_COMPONENT_MAP[type];

  if (!COMPONENT) {
    return AssetItem;
  }

  return COMPONENT;
};

export default getCardByType;
