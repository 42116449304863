import { invalidateCache } from '#/services';
import controlConfig from './config';
import * as controlUtils from './utils';

let extendedConfig = { ...controlConfig };

let accedoOneClientInstance = controlUtils.getControlClient(extendedConfig);

export const accedoOneClientInstanceBuilder = user => {
  if (user) {
    extendedConfig = {
      ...extendedConfig,
      gid: user.displayName === 'KIDDO' ? 'kids' : ''
    };
    accedoOneClientInstance = controlUtils.getControlClient(extendedConfig);
  }
  return accedoOneClientInstance;
};
